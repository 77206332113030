import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import LogoutButton from './LogoutButton';

export default function UserButton() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    isAuthenticated,
    user,
  } = useAuth0();
  const { name, picture } = user;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {isAuthenticated && (
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Avatar
              alt={name}
              src={picture}
              sx={{ width: 42, height: 42 }}
            />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => {
              handleClose();
            }}
            >
              My Account

            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}><LogoutButton /></MenuItem>
          </Menu>
        </div>
      )}
    </div>
  );
}
