import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Paper, Stack, Button } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import Divider from '@mui/material/Divider';
import { searchClimateIndex, fetchAllInClimateIndex } from '../../../api/search';
import { Colors } from '../../AppTheme';

export default function CustomizedInputBase({ callback }) {
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [activeFilterButton, setActiveFilterButton] = useState(null);
  const filters = [
    {
      topic: 'ALL',
    },
    {
      topic: 'CLIMATE',
    },
    {
      topic: 'OCEAN',
    },
    {
      topic: 'LANDS',
    },
    {
      topic: 'FRESHWATER',
    },
    {
      topic: 'PEOPLE',
    },
  ];

  /**
   * Utility function to search al items
   */
  async function searchAll() {
    const results = await fetchAllInClimateIndex();
    if (results.length > 0) {
      setSearchResults(results);
    }
  }

  useEffect(() => {
    searchAll();
  }, []);

  /**
   * Handles key press event when user is focused on the search input
   * @param {*} e
   */
  const handleSearchInputKeyPress = async (e) => {
    if (e.key === 'Enter') {
      // get search term
      const searchTerm = e.target.value;
      if (searchTerm.length > 0) {
        // request from search API endpoint
        const results = await searchClimateIndex(searchTerm);
        if (results.length > 0) {
          setSearchResults(results);
        } else {
          // zero search results will be reported - so user needs to clear (button) or delete with keyboard
          setSearchResults([]);
        }

        // clear any clicked/active filter button
        setActiveFilterButton(null);
      }
    }
  };

  useEffect(() => {
    // send to parent component
    callback(searchResults);
  }, [searchResults]);

  /**
   * Handles when the search input changes (e.g. user is typing)
   */
  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    // if user deletes all search text, clear the search results
    if (searchText.length === 0) {
      searchAll();
    }
  }, [searchText]);

  /**
   * Handles when the user clicks the cancel button in search input
   */
  const handleCancelButtonClick = () => {
    // clear the user's search text
    setSearchText('');

    // after clearing, get all
    searchAll();
  };

  /**
   * Handles when the user clicks a filter button to filter search results
   * @param {*} filterButtonIndex
   */
  const handleFilterClick = async (filterButtonIndex) => {
    // skip if filter button is already active
    if (activeFilterButton === filterButtonIndex) {
      return;
    }

    // set active filter button
    setActiveFilterButton(filterButtonIndex);

    // get filter button text
    const filterText = filters[filterButtonIndex].topic;

    let results = [];
    if (filterText === 'ALL') {
      // search all
      results = await fetchAllInClimateIndex();
      if (results.length > 0) {
        setSearchResults(results);
      }
    } else {
      // search for filter button text
      results = await searchClimateIndex(filterText);
      if (results.length > 0) {
        // send to parent component
        callback(results);
      }
    }
  };

  return (
    <div>
      <Paper
        component="form"
        sx={{
          p: '2px 4px', display: 'flex', alignItems: 'center', width: 420,
        }}
      >
        <InputBase
          value={searchText}
          onChange={handleSearchInputChange}
          onKeyPress={handleSearchInputKeyPress}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search by Keyword"
        />
        {(searchText.length > 0) ? (
          <>
            <IconButton color="primary" sx={{ p: '10px' }} onClick={handleCancelButtonClick}>
              <CancelIcon style={{ fontSize: 'medium', color: Colors.gray }} />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          </>
        ) : (null)}
        <IconButton type="submit" sx={{ p: '10px' }} disabled>
          <SearchIcon />
        </IconButton>
      </Paper>
      <Stack direction="row" spacing={1} style={{ margin: '16px 0' }}>
        {filters.map((filter, index) => (
          <Button
            key={uuidv4()}
            sx={{
              background: activeFilterButton === index ? Colors.yellowActive : Colors.yellow,
              color: '#000000',
              '&:hover': {
                backgroundColor: Colors.yellowActive,
              },
            }}
            variant="contained"
            disableElevation
            style={{ borderRadius: 0 }}
            onClick={() => handleFilterClick(index)}
          >
            {filter.topic}
          </Button>
        ))}
      </Stack>
    </div>
  );
}
