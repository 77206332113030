import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { v4 as uuidv4 } from 'uuid';
import HomeCard from './HomeCard';
import { Colors } from '../AppTheme';
import background1 from '../../assets/imgs/backgrounds/chuttersnap-9G1kXjqOq4U-unsplash.jpeg';
import background2 from '../../assets/imgs/backgrounds/greg-rosenke-59lah7lM6-Y-unsplash.jpeg';
import background3 from '../../assets/imgs/backgrounds/ivan-rohovchenko-RNNP_fqWeQo-unsplash.jpeg';
import background4 from '../../assets/imgs/backgrounds/luca-bravo-A-fubu9QJxE-unsplash.jpeg';

const data = [
  {
    topic: 'CLIMATE',
    goals: [
      {
        targets: [
          {
            value: '3 GT',
            subject: 'CO2 Removed or Sequestered',
          },
        ],
        targetDescription: 'Remove of sequester 3 billion metric tons of carbon dioxide emissions (CO2e) per year - the same as removing 650 million cars off the road.',
        targetStrategy: 'Using the power of nature to store carbon, and the strength of policy to cut emissions dquivalent to nearly a tenth of global emissions from fossil fuels.',
        progress: [
          { value: 55 },
          { value: 45 },
        ],
      },
      {
        targets: [
          {
            value: '100M',
            subject: 'People Benefited',
          },
        ],
        targetDescription: 'Help 100 million people who are most likely to be affected by climate-related emergencies such as flood, fires, and drought.',
        targetStrategy: 'Investing in nature to improve the health of habitats and equitably protect people in coastal communities.',
        progress: [
          { value: 70 },
          { value: 30 },
        ],
      },
    ],
  },
  {
    topic: 'OCEAN',
    goals: [
      {
        targets: [
          {
            value: '4B',
            subject: 'Hectares Conserved',
          },
        ],
        targetDescription: 'Conserve 4 billion hectares of ocean - more than 10% of the world\'s ocean area',
        targetStrategy: 'Making sure that the ocean thrives through new and btetter-managed prtected areas, sustainable fishing practices and positive policy changes',
        progress: [
          { value: 65 },
          { value: 35 },
        ],
      },
    ],
  },
  {
    topic: 'LANDS',
    goals: [
      {
        targets: [
          {
            value: '650M',
            subject: 'Hectares Conserved',
          },
        ],
        targetDescription: 'Conserve 650 million hectares of healthy lands, suh as forests and grasslands - an area twice the size of India.',
        targetStrategy: 'Partnering to improve management of working lands, elevating the efforts of Indigenous peoples, and suporting better forest management to sequester carbon.',
        progress: [
          { value: 90 },
          { value: 10 },
        ],
      },
    ],
  },
  {
    topic: 'FRESHWATER',
    goals: [
      {
        targets: [
          {
            value: '1M',
            subject: 'KM of Rivers Conserved',
          },
          {
            value: '30M',
            subject: 'HA of Lakes & Wetlands Conserved',
          },
        ],
        targetDescription: 'Conserve 1 million km of river systems and 30 million hectares of lakes and wetlands - enough river length to circumnavigate the globe 25 times.',
        targetStrategy: 'Engaging in collaborative partnerships and promoting policies that improve the quality and amount of water available in freshwater ecosystems and communities.',
        progress: [
          { value: 85 },
          { value: 15 },
        ],
      },
    ],
  },
  {
    topic: 'PEOPLE',
    goals: [
      {
        targets: [
          {
            value: '45M',
            subject: 'People Supported',
          },
        ],
        targetDescription: 'Support 45 million people who depend on ocean, freshwater and lands for their wellbeing and livlihoods.',
        targetStrategy: 'Ensuring equitable access for people who rely on landscapes and seascales so they can improve their economic opportunities, secure rights to resources and better shape their future.',
        progress: [
          { value: 80 },
          { value: 20 },
        ],
      },
    ],
  },
];

function Home() {
  const [backgroundIndex, setBackgroundIndex] = useState(0);
  const backgrounds = [
    `url(${background1})`,
    `url(${background3})`,
    `url(${background4})`,
    `url(${background2})`,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundIndex((prevIndex) => (prevIndex + 1) % backgrounds.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div style={{
        padding: 16,
        backgroundColor: 'white',
        height: 'calc(100vh - 170px)',
        overflow: 'scroll',
        position: 'relative',
      }}
      >
        <div style={{
          padding: '100px 0',
          width: '55%',
          margin: 'auto',
          position: 'relative',
          zIndex: 3,
        }}
        >
          <div style={{ fontSize: '40px', color: Colors.offwhite }}>
            We are facing the biggest, most complex challenges of our lives. And that calls for our biggest, most ambitious plans -
            {' '}
            <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>for people and nature.</span>
          </div>
        </div>
        <div style={{ position: 'absolute', zIndex: 4 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            // columns={{ xs: 4, sm: 8, md: 12 }}
            columns={{ md: 6 }}
            alignItems="center"
            justifyContent="center"
            style={{ zIndex: 4 }}
          >
            {data.map((item) => (
              <Grid item xs={4} sm={4} md={4} key={uuidv4()}>
                <HomeCard data={item} key={uuidv4()} />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      <div style={{
        position: 'absolute',
        top: 76,
        left: 0,
        bottom: 58,
        width: '100%',
        backgroundColor: '#000000',
        backgroundSize: '100% 100%',
        opacity: 0.5,
        zIndex: 2,
      }}
      />
      <div style={{
        position: 'absolute',
        top: 76,
        left: 0,
        bottom: 58,
        width: '100%',
        backgroundImage: backgrounds[backgroundIndex],
        backgroundSize: '100% 100%',
        animation: 'fade-rotate 20s ease-in-out infinite',
        transition: 'background-image 2s ease-in-out',
        opacity: 1,
        zIndex: 1,
      }}
      />
    </>
  );
}

export default Home;
