import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-unresolved
import { TwitterPicker } from 'react-color';
import {
  Box,
  Stack,
  Typography,
  Accordion,
  Button,
  AccordionDetails,
  IconButton,
} from '@mui/material';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import chroma from 'chroma-js';
import ColorMap from './ColorMap';
import { Colors } from '../AppTheme';

function Legend({
  items, units, title, layerType, callback,
}) {
  //
  console.log('layerType', layerType);
  const [colorsShowing, setColorsShowing] = useState(false);
  const [isReversed, setIsReversed] = useState(false);
  const [singleColor, setSingleColor] = useState();

  /**
   * Handle when a user clicks legend to change color map
   */
  const handleLegendClick = () => {
    setColorsShowing(!colorsShowing);
  };

  /**
   * Handle event when user clicks on color map option
   * @param {*} colorMap
   */
  const handleColorMapClick = (colorMap) => {
    // get array of hex colors from the chosen colormap
    const colors = chroma.scale(colorMap).mode('lch').colors(5);

    let colorMapName = colorMap;
    // handle reverse
    if (isReversed) {
      // reverse color order
      colors.reverse();

      // update color map name
      colorMapName += '_r';
    }

    // send chosen colormap hex color array to parent
    callback({
      name: colorMapName, // used for raster symboogy
      colors, // used for vector symbology
    });
  };

  /**
   * Handles event when user clicks on the reverse button
   */
  const handleReverseLegendClick = () => {
    setIsReversed(!isReversed);
  };

  /**
   * Handles when a user changes the color pickder
   * @param {*} color
   */
  const handleColorPickerChange = (color) => {
    // change the hex in the component
    setSingleColor(color.hex);

    // send chosen color to parent
    callback({
      name: null,
      colors: [color.hex],
    });
  };

  return (
    (items && title) ? (
      <div style={{
        zIndex: 3,
        padding: '16px',
        borderRadius: 4,
        backgroundColor: Colors.lighterBackground,
        color: Colors.lightGray,
        zindex: 10000,
        cursor: 'pointer',
      }}
      >
        <Box style={{ width: '100%' }} onClick={handleLegendClick}>
          <Typography style={{ marginBottom: 10 }}>{title}</Typography>
          <Stack spacing={1}>
            {items.map((item) => (
              <Stack key={item.text} direction="row">
                {(layerType === 'fill' || layerType === 'raster') ? (
                  <div style={{
                    backgroundColor: item.color, width: 24, height: 18, borderRadius: 2, marginRight: 8,
                  }}
                  />
                ) : (null)}
                {(layerType === 'circle' && item['color-outline']) ? (
                  <div style={{
                    backgroundColor: item.color, width: 9, height: 9, borderRadius: 6, borderStyle: 'solid', borderWidth: '1 px', borderColor: item['color-outline'], marginRight: 8, marginTop: 2,
                  }}
                  />
                ) : (null)}
                {(layerType === 'circle' && !item['color-outline'] === undefined) ? (
                  <div style={{
                    backgroundColor: item.color, width: 11, height: 9, borderRadius: 6, marginRight: 8, marginTop: 4,
                  }}
                  />
                ) : (null)}
                {(layerType === 'line') ? (
                  <div style={{
                    backgroundColor: item.color, width: 24, height: 4, marginRight: 8, marginTop: 6,
                  }}
                  />
                ) : (null)}
                <Typography variant="body2">{item.text}</Typography>
              </Stack>
            ))}
          </Stack>
        </Box>
        {(colorsShowing && items.length > 1) ? (
          <div>
            <Stack direction="row">
              <Typography variant="body2" style={{ marginTop: 8 }}>Color Pallette:</Typography>
              <IconButton size="small" style={{ marginLeft: 80 }} onClick={handleReverseLegendClick}>
                <UnfoldLessIcon style={{ color: Colors.gray }} />
              </IconButton>
            </Stack>
            <Accordion
              expanded
              style={{
                margin: '0 0 8px 0',
                maxHeight: 436,
                overflow: 'auto',
              }}
            >
              <AccordionDetails style={{ backgroundColor: Colors.darkBackground, padding: '8px 8px 8px 0' }}>
                <Button variant="text" onClick={() => handleColorMapClick('greys')}><ColorMap colorMap="greys" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('purples')}><ColorMap colorMap="purples" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('blues')}><ColorMap colorMap="blues" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('greens')}><ColorMap colorMap="greens" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('oranges')}><ColorMap colorMap="oranges" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('reds')}><ColorMap colorMap="reds" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('ylorbr')}><ColorMap colorMap="ylorbr" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('ylorrd')}><ColorMap colorMap="ylorrd" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('orrd')}><ColorMap colorMap="orrd" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('purd')}><ColorMap colorMap="purd" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('rdpu')}><ColorMap colorMap="rdpu" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('bupu')}><ColorMap colorMap="bupu" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('gnbu')}><ColorMap colorMap="gnbu" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('pubu')}><ColorMap colorMap="pubu" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('ylgnbu')}><ColorMap colorMap="ylgnbu" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('pubugn')}><ColorMap colorMap="pubugn" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('bugn')}><ColorMap colorMap="bugn" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('ylgn')}><ColorMap colorMap="ylgn" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('viridis')}><ColorMap colorMap="viridis" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('piyg')}><ColorMap colorMap="piyg" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('prgn')}><ColorMap colorMap="prgn" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('brbg')}><ColorMap colorMap="brbg" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('puor')}><ColorMap colorMap="puor" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('rdgy')}><ColorMap colorMap="rdgy" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('rdylbu')}><ColorMap colorMap="rdylbu" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('rdylgn')}><ColorMap colorMap="rdylgn" mode="lch" isReversed={isReversed} /></Button>
                <Button variant="text" onClick={() => handleColorMapClick('spectral')}><ColorMap colorMap="spectral" mode="lch" isReversed={isReversed} /></Button>
              </AccordionDetails>
            </Accordion>
          </div>
        ) : (null)}
        {(colorsShowing && items.length === 1) ? (
          <div style={{ marginTop: 12, marginLeft: '-8px' }}>
            <TwitterPicker
              color={singleColor}
              width="204"
              onChangeComplete={handleColorPickerChange}
              colors={['#b71c1c', '#d32f2f', '#f44336', '#e57373', '#ffcdd2',
                '#880e4f', '#c2185b', '#e91e63', '#f06292', '#f8bbd0',
                '#4a148c', '#7b1fa2', '#9c27b0', '#ba68c8', '#e1bee7',
                '#311b92', '#512da8', '#673ab7', '#9575cd', '#d1c4e9',
                '#1a237e', '#303f9f', '#3f51b5', '#7986cb', '#c5cae9',
                '#0d47a1', '#1976d2', '#2196f3', '#64b5f6', '#bbdefb',
                // '#01579b', '#0288d1', '#03a9f4', '#4fc3f7', '#b3e5fc',
                '#006064', '#0097a7', '#00bcd4', '#4dd0e1', '#b2ebf2',
                '#004d40', '#00796b', '#009688', '#4db6ac', '#b2dfdb',
                '#194d33', '#388e3c', '#4caf50', '#81c784', '#c8e6c9',
                // '#33691e', '#689f38', '#8bc34a', '#aed581', '#dcedc8',
                '#827717', '#afb42b', '#cddc39', '#dce775', '#f0f4c3',
                '#f57f17', '#FBC02D', '#FFEB3B', '#FFF176', '#FFF9C4',
                // '#FF6F00', '#FFA000', '#FFC107', '#FFD54F', '#FFECB3',
                // '#E65100', '#F57C00', '#FF9800', '#FFB74D', '#FFE0B2',
                '#BF360C', '#E64A19', '#FF5722', '#FF8A65', '#FFCCBC',
                '#3E2723', '#5D4037', '#795548', '#A1887F', '#D7CCC8',
                '#263238', '#455A64', '#607D8B', '#90A4AE', '#CFD8DC',
                '#000000', '#525252', '#969696', '#D9D9D9', '#FFFFFF',
              ]}
            />
          </div>
        )
          : (null)}
        {(units) ? (<Typography variant="body2" style={{ marginTop: 8 }}>{`Units: ${units}`}</Typography>) : (null)}
      </div>
    ) : (null)
  );
}

export default Legend;
