import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ThemeProvider } from '@emotion/react';
import { AppTheme } from './AppTheme';
import Home from './home/Home';
import Catalog from './catalog/Catalog';
import CatalogDetails from './catalog/CatalogDetails';
import Map from './map/Map';
import Profile from './profile/Account';
import Loading from './Loading';
import ProtectedRoute from './auth/ProtectedRoute';
import Navbar from './Navbar';
import Footer from './Footer';

function App() {
  const { isLoading } = useAuth0();
  return ((isLoading) ? (
    <Loading />
  ) : (
    <ThemeProvider theme={AppTheme}>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <ProtectedRoute path="/catalog" exact component={Catalog} />
          <ProtectedRoute path="/catalogdetails" component={CatalogDetails} />
          <ProtectedRoute path="/map" exact component={Map} />
          <ProtectedRoute path="/profile" component={Profile} />
        </Switch>
        <Footer />
      </Router>
    </ThemeProvider>
  ));
}

export default App;
