import React from 'react';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import { IconButton } from '@mui/material';
import { Colors } from '../AppTheme';

function ScreenshotButton({ map }) {
  /**
   * Handles when a user clicks the button to slide the layer panel on or off
   */
  const handleButtonClick = () => {
    // date time stamp
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    const second = String(now.getSeconds()).padStart(2, '0');
    const dateTimeString = `${year}-${month}-${day}-${hour}${minute}${second}`;

    // take screenshot
    map.getCanvas().toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `ademo_screenshot_${dateTimeString}.png`;
      link.target = '_blank'; //
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  return (
    <IconButton
      size="small"
      className="button-projection"
      style={{
        backgroundColor: Colors.offwhite,
        position: 'absolute',
        top: 334,
        right: 8,
      }}
      onClick={handleButtonClick}
    >
      <ScreenshotMonitorIcon style={{ color: 'black' }} />
    </IconButton>
  );
}

export default ScreenshotButton;
