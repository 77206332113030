/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Chip,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { Colors } from '../AppTheme';
// import JupyterNotebook from './JupyterNotebook';

function CatalogDetails({ targetDataset }) {
  const history = useHistory();
  const [dataset, setDataset] = useState();

  useEffect(() => {
    setDataset(targetDataset);
  }, []);

  /**
   * Utility to render a dataset property in Catalog Details
   * @param {*} dataset The dataset object
   * @param {*} property The name of the dataset property (e.g. title)
   * @param {*} propertyAlias The alias for the dataset property
   * @returns JSX for rendering a dataset property in Catalog Details
   */
  const renderDatasetProperty = (dataset, property, propertyAlias) => {
    if (dataset && dataset[property]?.length > 0) {
      return (
        <div>
          <Typography variant="h4" style={{ color: Colors.darkBlue, fontWeight: 600 }}>
            {propertyAlias}
          </Typography>
          <Typography variant="body1">{dataset[property]}</Typography>
        </div>
      );
    }
    return null;
  };

  /**
   * Utility to render a dataset URL Links in Catalog Details
   * @param {*} dataset The dataset object
   * @param {*} property The name of the dataset property (e.g. licenseLink)
   * @param {*} propertyAlias The alias for the dataset property
   * @returns JSX for rendering a dataset property in Catalog Details
   */
  const renderDatasetLink = (dataset, property, propertyAlias) => {
    if (dataset && dataset[property]?.length > 0) {
      return (
        <div>
          <Typography variant="h4" style={{ color: Colors.darkBlue, fontWeight: 600 }}>
            {propertyAlias}
          </Typography>
          <Link href={dataset[property]} target="_blank">
            <Typography variant="body1" style={{ color: Colors.darkBlue, wordBreak: 'break-all' }}>
              {dataset[property]}
            </Typography>
          </Link>
        </div>
      );
    }
    return null;
  };

  /**
   * Utility to render a dataset Tags in Catalog Details
   * @param {*} dataset The dataset object
   * @param {*} property The name of the dataset property (e.g. licenseLink)
   * @param {*} propertyAlias The alias for the dataset property
   * @returns JSX for rendering a dataset property in Catalog Details
   */
  const renderDatasetTags = (dataset, property, propertyAlias) => {
    if (dataset && dataset[property]?.length > 0) {
      return (
        <div>
          <Typography variant="h4" style={{ color: Colors.darkBlue, fontWeight: 600 }}>
            {propertyAlias}
          </Typography>
          <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="flex-start" style={{ marginTop: 8 }}>
            {dataset.tags.split(',').map((tag) => (
              <Chip key={tag} label={tag} size="small" />
            ))}
          </Stack>
        </div>
      );
    }
    return null;
  };

  const handleLaunchMapClick = (datasetId) => {
    history.push(`/map?id=${datasetId}`);
  };

  return (
    <div style={{
      backgroundColor: '#f2f2f2', overflow: 'scroll',
    }}
    >
      {(dataset) ? (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <div>
                <div style={{ backgroundColor: 'white', borderRadius: 4, padding: 16 }}>
                  {renderDatasetProperty(dataset, 'title', 'Title')}
                  <br />
                  {renderDatasetProperty(dataset, 'description', 'Description')}
                  <br />
                  {renderDatasetTags(dataset, 'tags', 'Tags')}
                </div>
                <br />
                {/* map */}
                <div>
                  <div style={{
                    backgroundColor: 'white', borderRadius: 4, padding: 16, overflow: 'hidden',
                  }}
                  >
                    <Typography variant="h4" style={{ color: Colors.darkBlue, fontWeight: 600 }}>Map Thumbnail</Typography>
                    <img
                      src={`${dataset.linkToMapScreenshot}?access_token=${process.env.REACT_APP_MAPBOXGLJS_ACCESS_TOKEN}`}
                      alt="screenshot"
                      style={{
                        width: '100%', height: '100%', objectFit: 'cover', marginTop: 8, cursor: 'pointer',
                      }}
                      onClick={() => handleLaunchMapClick(dataset.id)}
                    />
                    <Button style={{ marginTop: 10 }} variant="contained" disableElevation onClick={() => handleLaunchMapClick(dataset.id)}>
                      <Typography variant="body2">Launch Map</Typography>
                    </Button>
                  </div>
                </div>
                <br />
                <div style={{ backgroundColor: 'white', borderRadius: 4, padding: 16 }}>
                  {renderDatasetProperty(dataset, 'sourceOrganization', 'Source')}
                  <br />
                  {renderDatasetLink(dataset, 'linkToSource', 'Link to Source')}
                  <br />
                  {renderDatasetLink(dataset, 'linkToMethodology', 'Link to Methodoloy')}
                </div>
                <br />
                <div style={{ display: 'flex' }}>
                  <div style={{
                    backgroundColor: 'white', borderRadius: 4, padding: 16, width: '50%', display: 'inline-block', marginRight: 8,
                  }}
                  >
                    {renderDatasetProperty(dataset, 'spatialResolution', 'Spatial Resolution')}
                    <br />
                    {renderDatasetProperty(dataset, 'temporalResolution', 'Temporal Resolution')}
                    <br />
                    {renderDatasetProperty(dataset, 'geographicalCoverage', 'Geographic Coverage')}
                    <br />
                    {renderDatasetProperty(dataset, 'dataFormat', 'Data Format')}
                  </div>
                  <div style={{
                    backgroundColor: 'white', borderRadius: 4, padding: 16, width: '50%', display: 'inline-block', marginLeft: 8,
                  }}
                  >
                    {renderDatasetProperty(dataset, 'license', 'License')}
                    <br />
                    {renderDatasetLink(dataset, 'licenseLink', 'Link to License')}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
        // <div>
        //   <JupyterNotebook
        //     url="https://nbviewer.org/urls/climateinitiative.s3.us-west-2.amazonaws.com/notebooks/Analysis_Alpha2_RiskProfile.ipynb"
        //   />
        // </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}

export default CatalogDetails;
