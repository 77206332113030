import React from 'react';
import Stack from '@mui/material/Stack';
import { Colors } from './AppTheme';

function Footer() {
  return (
    <div style={{
      position: 'static',
      height: '42px',
      backgroundColor: Colors.darkBlue,
      color: '#FFFFFF',
      fontSize: '10pt',
      padding: '24px 0 0 16px',
    }}
    >
      <Stack direction="row" spacing={4}>
        <div>&copy;&nbsp;Ademo</div>
        <div>|</div>
        <div>Terms of Use</div>
        <div>|</div>
        <div>Privacy Policy</div>
      </Stack>
    </div>
  );
}

export default Footer;
