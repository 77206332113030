import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  // Button,
  Dialog,
  // DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Stack,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import LayersIcon from '@mui/icons-material/Layers';
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from '../AppTheme';
import CardTemplate from '../catalog/CardTemplate';
import Search from '../catalog/search/Search';

function LayerPanel({ children, callback }) {
  // layer panel showing
  const [layerPanelIsShowing, setLayerPanelIsShowing] = useState(true);

  // open state for the Add Data Dialog
  const [open, setOpen] = React.useState(false);

  const [data, setData] = useState([]);

  /**
   * Handles when a user clicks the button to slide the layer panel on or off
   */
  const toggleLayerPanel = () => {
    setLayerPanelIsShowing((prev) => !prev);
  };

  /**
   * Handles when a user clicks the button to open the Dialog
   */
  const handleDialogOpen = () => () => {
    setOpen(true);
  };

  /**
   * Handles when a user clicks the button to close the Dialog
   */
  const handleDialogClose = () => {
    setOpen(false);
  };

  /**
   * For the Add Data Dialog, this will focus on the description element
   */
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  /**
   * Callback sent to Search component - handles when the user changes the search text.
   * @param {*} searchResults
   */
  const callbackSearchChanged = (searchResults) => {
    // change data in local state to update cards
    setData(searchResults);
  };

  return (
    <div style={{
      position: 'absolute',
      top: 78,
      left: 0,
      zIndex: 2,
    }}
    >
      {/* Button that toggles sliding in of the layer panel */}
      <IconButton
        size="small"
        style={{
          position: 'absolute',
          top: 16,
          left: 16,
          backgroundColor: Colors.lighterBackground,
          zIndex: 2,
        }}
        onClick={toggleLayerPanel}
      >
        {(layerPanelIsShowing) ? (<KeyboardArrowLeftIcon style={{ color: 'white' }} />) : (<KeyboardArrowRightIcon style={{ color: 'white' }} />)}
      </IconButton>
      {/* layer panel */}
      <Slide direction="right" in={layerPanelIsShowing} mountOnEnter unmountOnExit>
        <div style={{
          backgroundColor: Colors.darkBackground,
          position: 'absolute',
          top: 10,
          left: 10,
          borderRadius: 4,
          // opacity: 0.95,
        }}
        >
          {/* layer panel content */}
          <div style={{
            // minWidth: 260,
            // maxWidth: 298,
            width: 298,
          }}
          >
            {/* layer panel header */}
            <div style={{
              backgroundColor: Colors.darkBackground,
              height: 46,
            }}
            >
              <Stack
                direction="row"
                spacing={2}
              >
                <Typography style={{
                  paddingLeft: 52,
                  paddingTop: 12,
                  color: Colors.blue,
                }}
                >
                  Layers
                </Typography>
                <IconButton
                  style={{
                    // margin: '4px 0px 0px 120px',
                    margin: '4px 0px 0px 160px',
                  }}
                  onClick={handleDialogOpen('paper')}
                >
                  <LayersIcon fontSize="small" style={{ color: Colors.blue }} />
                </IconButton>
              </Stack>
            </div>
            <div style={{ padding: '8px 16px 16px 16px' }}>
              {children}
            </div>
          </div>
        </div>
      </Slide>
      {/* add data dialog */}
      <Dialog
        open={open}
        onClose={handleDialogClose}
        scroll="paper"
        maxWidth="lg"
      >
        <IconButton
          style={{
            position: 'absolute',
            right: '8px',
            top: '8px',
          }}
          onClick={handleDialogClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle style={{ fontSize: 14 }}>ADD DATA TO MAP</DialogTitle>
        <DialogContent dividers>
          {/* search component */}
          <div style={{ marginBottom: 16 }}>
            <Search callback={callbackSearchChanged} />
          </div>
          {(data.length > 0) ? (
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              {data.map((item) => (
                <Grid item xs={4} sm={4} md={3} key={uuidv4()}>
                  <CardTemplate
                    data={item}
                    callback={() => {
                      callback(item);
                      setOpen(false);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: '-156px' }}
              minHeight="calc(100vh)"
            >
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogClose}>Add</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

export default LayerPanel;
