import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Stack from '@mui/material/Stack';
import chroma from 'chroma-js';

function ColorMap({ colorMap, mode, isReversed }) {
  const colors = chroma.scale(colorMap).mode(mode).colors(5);
  if (isReversed) {
    colors.reverse();
  }

  /**
   * Utility to render different legends based on type.
   */
  const renderLegend = () => {
    if (colors) {
      return (
        <div>
          <Stack direction="row" spacing={0}>
            {colors.map((item) => (
              <div key={uuidv4()}>
                <div style={{
                  backgroundColor: item,
                  width: 36,
                  height: 10,
                }}
                />
                {null}
              </div>
            ))}
          </Stack>
        </div>
      );
    }
    // return null;
    return (<div>No</div>);
  };

  return (
    <div>
      {renderLegend()}
    </div>
  );
}

export default ColorMap;
