import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import UserButton from './auth/UserButton';
import LoginButton from './auth/LoginButton';
import fabric from '../assets/imgs/fabric.png';
import { Colors } from './AppTheme';

function Navbar() {
  const [tabValue, setTabValue] = React.useState(0);
  const history = useHistory();
  const location = useLocation();
  const { isAuthenticated } = useAuth0();

  // Utility for selecting the tab based on the the path
  const selectTabBasedOnPath = (path) => {
    switch (path) {
      case '/':
        setTabValue(0);
        break;
      case '/catalog':
        setTabValue(1);
        break;
      case '/catalogdetails':
        setTabValue(1);
        break;
      case '/map':
        setTabValue(2);
        break;
      case '/profile':
        setTabValue(3);
        break;
      default:
        setTabValue(0);
    }
  };

  // For selecting the tab whenever the url changes (from this component or when another component changes route)
  useEffect(() => {
    selectTabBasedOnPath(location.pathname);
  }, [location.pathname]);

  // For selecting the tab when the backbutton is clicked, called each time the browser back and forward buttons are clicked
  useEffect(() => {
    window.onpopstate = () => {
      selectTabBasedOnPath(history.location.pathname);
    };
  }, [history.location.pathname]);

  // Handles when a user clicks on a tab
  const handleChange = (event, newValue) => {
    // highlight the tab
    setTabValue(newValue);

    // set route based on tab value
    switch (newValue) {
      case 0:
        history.push('/');
        break;
      case 1:
        history.push('/catalog');
        break;
      case 2:
        history.push('/map');
        break;
      case 3:
        history.push('/profile');
        break;
      default:
        history.push('/');
    }
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{
          height: 76,
          borderBottom: 'solid #e4e4e4',
        }}
      >
        <Grid item style={{ margin: '0 0 0 16px' }}>
          <Stack direction="row" spacing={2}>
            <img alt="logo" src={fabric} width="50px" height="50px" style={{ float: 'left' }} />
            <div style={{
              fontSize: 28, fontWeight: 400, color: Colors.navGray, paddingTop: 8,
            }}
            >
              Ademo
            </div>
          </Stack>
        </Grid>
        <Grid item style={(isAuthenticated) ? { height: 57, marginRight: 16 } : { height: 20, marginRight: 16 }}>
          <Stack sx={{ display: { xs: 'none', md: 'block' } }} direction="row" spacing={2}>
            <Tabs value={tabValue} onChange={handleChange}>
              <Tab label="Dashboard" style={{ color: Colors.navGray }} />
              {isAuthenticated && <Tab label="Data Catalog" style={{ color: Colors.navGray }} />}
              {isAuthenticated && <Tab label="Map Explorer" style={{ color: Colors.navGray }} />}
              {(isAuthenticated) ? (<UserButton style={{ float: 'right', height: 66 }} />) : (<LoginButton />)}
            </Tabs>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}

export default Navbar;
