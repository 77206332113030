import axios from 'axios';

export const searchClimateIndex = async (searchTerm) => {
  const results = [];
  const url = `${process.env.REACT_APP_OPENSEARCH_API_BASEURL}/api/search/index/${process.env.REACT_APP_SEARCH_INDEX}?searchTerm=${searchTerm}`;
  try {
    const response = await axios.get(url);

    if (response.data.hits.hits.length > 0) {
      response.data.hits.hits.forEach((hit) => {
        // eslint-disable-next-line no-underscore-dangle
        results.push(hit._source);
      });
    }

    return results;
  } catch (error) {
    return error.toJSON();
  }
};

export const fetchAllInClimateIndex = async () => {
  const results = [];
  const url = `${process.env.REACT_APP_OPENSEARCH_API_BASEURL}/api/search?index=${process.env.REACT_APP_SEARCH_INDEX}&query={"query": {"match_all": {}},"from": 0,"size": 100}`;
  try {
    const response = await axios.get(url);

    if (response.data.hits.hits.length > 0) {
      response.data.hits.hits.forEach((hit) => {
        // eslint-disable-next-line no-underscore-dangle
        results.push(hit._source);
      });
    }

    return results;
  } catch (error) {
    return error.toJSON();
  }
};
