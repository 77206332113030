import React, { useState } from 'react';
import {
  Button,
  Box,
  CircularProgress,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';
import CardTemplate from './CardTemplate';
import Search from './search/Search';
import CatalogDetails from './CatalogDetails';

function Catalog() {
  // catalog items
  const [data, setData] = useState([]);

  // target dataset for details
  const [targetDataset, setTargetDataset] = useState();

  // open state for the Add Data Dialog
  const [open, setOpen] = React.useState(false);

  /**
   * Callback sent to Search component - handles when the user changes the search text.
   * @param {*} searchResults
   */
  const callbackSearchChanged = (searchResults) => {
    // change data in local state to update cards
    setData(searchResults);
  };

  /**
   * Callback sent to CardTemplate component - handles when the user clicks on a card
   */
  const callbackCardClicked = (item) => {
    // set target dataset for details
    setTargetDataset(item);

    // open dataset details dialog
    setOpen(true);
  };

  /**
   * Handles when a user clicks the button to close the Dialog
   */
  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <div style={{
      padding: 16, backgroundColor: '#f2f2f2', height: 'calc(100vh - 170px)', overflow: 'scroll',
    }}
    >
      <div style={{ marginBottom: 16 }}>
        <Search callback={callbackSearchChanged} />
      </div>
      {(data.length > 0) ? (
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {data.map((item) => (
            <Grid item xs={4} sm={4} md={3} key={uuidv4()}>
              <CardTemplate data={item} callback={callbackCardClicked} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '-156px' }}
          minHeight="calc(100vh)"
        >
          <CircularProgress />
        </Box>
      )}
      {/* dataset details dialog */}
      <Dialog
        open={open}
        onClose={handleDialogClose}
        scroll="paper"
        maxWidth="md"
      >
        <IconButton
          style={{
            position: 'absolute',
            right: '8px',
            top: '8px',
          }}
          onClick={handleDialogClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle style={{ fontSize: 14 }}>DATASET DETAILS</DialogTitle>
        <DialogContent dividers style={{ backgroundColor: '#f2f2f2' }}>
          {(targetDataset) ? (
            <CatalogDetails targetDataset={targetDataset} />)
            : (null)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Catalog;
