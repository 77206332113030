import React, { useEffect } from 'react';
import {
  Slider,
  Stack,
  Typography,
} from '@mui/material';
import { Colors } from '../AppTheme';
import './LayerOpacitySlider.css';

function LayerOpacitySlider({ callback }) {
  // opacity slider
  const [opacitySliderValue, setOpacitySliderValue] = React.useState(80);

  /**
   * Handles when a user changes the slider value for the layer opacity
   * @param {*} e
   * @param {*} newValue
   */
  const handleOpacitySliderChange = (e, newValue) => {
    // update local state
    setOpacitySliderValue(newValue);
  };

  /**
   * useEffect when opacitySliderValue is changed (e.g. user changes the attribute filter slider position)
   */
  useEffect(() => {
    // send to parent via callback
    callback(opacitySliderValue);
  }, [opacitySliderValue]);

  return (
    <div className="opacity-slider" style={{ backgroundColor: Colors.lighterBackground }}>
      <Typography style={{ color: Colors.lightGray }}>
        Layer Opacity:
      </Typography>
      <Slider
        style={{ color: Colors.blue }}
        size="small"
        value={opacitySliderValue}
        valueLabelDisplay="auto"
        onChange={handleOpacitySliderChange}
      />
      <Stack direction="row" justifyContent="space-between" style={{ color: Colors.lightGray }}>
        <Typography variant="body2">0%</Typography>
        <Typography variant="body2">100%</Typography>
      </Stack>
    </div>
  );
}

export default LayerOpacitySlider;
