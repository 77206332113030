import React, { useState, useEffect } from 'react';
import {
  IconButton,
} from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import LanguageIcon from '@mui/icons-material/Language';
import { Colors } from '../AppTheme';
import './MapProjectionButton.css';

function MapProjectionButton({ map }) {
  const [mapProjection, setMapProjection] = useState('mercator');

  /**
   * Handles when a user clicks the button
   */
  const handleButtonClick = () => {
    //
    console.log(`mapProjection is: ${mapProjection}`);
    if (mapProjection === 'mercator') {
      setMapProjection('globe');
    } else {
      setMapProjection('mercator');
    }
  };

  useEffect(() => {
    if (map && map.isStyleLoaded()) {
      map.setProjection({ name: mapProjection });
    }
  }, [mapProjection]);

  return (
    (mapProjection === 'mercator') ? (
      <IconButton
        size="small"
        className="projection-button"
        style={{
          backgroundColor: Colors.offwhite,
          position: 'absolute',
          top: 290,
          right: 8,
        }}
        onClick={handleButtonClick}
      >
        <LanguageIcon style={{ color: 'black' }} />
      </IconButton>
    ) : (
      <IconButton
        size="small"
        className="button-projection"
        style={{
          backgroundColor: Colors.offwhite,
          position: 'absolute',
          top: 290,
          right: 8,
        }}
        onClick={handleButtonClick}
      >
        <MapIcon style={{ color: 'black' }} />
      </IconButton>
    )
  );
}

export default MapProjectionButton;
