/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import {
  Grid,
  IconButton,
  Button,
  Typography,
} from '@mui/material';
import LayersIcon from '@mui/icons-material/Layers';
import { Colors } from '../AppTheme';
import streets from './imgs/basemap_streets.png';
import aerial from './imgs/basemap_aerial.png';
// import outdoors from './imgs/basemap_outdoors.png';
import light from './imgs/basemap_light.png';
import dark from './imgs/basemap_dark.png';

function BasemapSwitcher({ callback }) {
  const [basemapsShowing, setBasemapsShowing] = useState(false);

  /**
   * Handles when user clicks on the base map switcher button
   */
  const handleBaseMapClick = () => {
    setBasemapsShowing(!basemapsShowing);
  };

  /**
   * Handles when user clicks on a basemap button
   */
  const handleBasemapClick = (targetBasemap) => {
    // notify parent component
    callback(targetBasemap);
  };

  return (
    <div>
      <IconButton
        size="small"
        style={{
          position: 'absolute',
          top: 378,
          right: 8,
          backgroundColor: Colors.offwhite,
          zIndex: 2,
        }}
        onClick={handleBaseMapClick}
      >
        <LayersIcon size="small" style={{ color: 'black' }} />
      </IconButton>
      {(basemapsShowing) ? (
        <Grid
          container
          spacing={0}
          style={{
            padding: '10px 8px 8px 8px',
            position: 'absolute',
            top: 400,
            right: 24,
            zIndex: 1,
            borderRadius: 4,
            backgroundColor: Colors.darkBackground,
            color: Colors.lightGray,
            zindex: 1,
            width: 248,
          }}
        >
          <Grid item style={{ padding: 0 }}>
            <Button onClick={() => handleBasemapClick('mapbox://styles/mapbox/streets-v12')}>
              <img style={{ borderRadius: 2 }} src={streets} alt="streets" height="100px" width="100px" />
            </Button>
            <Typography style={{ textAlign: 'center', margin: 'auto' }} variant="body2">Streets</Typography>
          </Grid>
          <Grid item style={{ padding: 0 }}>
            <Button onClick={() => handleBasemapClick('mapbox://styles/mapbox/satellite-streets-v11')}>
              <img style={{ borderRadius: 2 }} src={aerial} alt="aerial" height="100px" width="100px" />
            </Button>
            <Typography style={{ textAlign: 'center', margin: 'auto' }} variant="body2">Satellite</Typography>
          </Grid>
          <Grid item style={{ padding: 0 }}>
            <Button onClick={() => handleBasemapClick('mapbox://styles/spatialdev/clesuog1j000q01s8imt47fds')}>
              <img style={{ borderRadius: 2 }} src={light} alt="outdoors" height="100px" width="100px" />
            </Button>
            <Typography style={{ textAlign: 'center', margin: 'auto' }} variant="body2">Light</Typography>
          </Grid>
          <Grid item style={{ padding: 0 }}>
            <Button onClick={() => handleBasemapClick('mapbox://styles/spatialdev/ckxi7of9539yo16pa3p0mcu18')}>
              <img style={{ borderRadius: 2 }} src={dark} alt="dark" height="100px" width="100px" />
            </Button>
            <Typography style={{ textAlign: 'center', margin: 'auto' }} variant="body2">Dark</Typography>
          </Grid>
        </Grid>
      ) : (null) }
    </div>
  );
}

export default BasemapSwitcher;
