/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import { Divider } from '@mui/material';
import { useLocation } from 'react-router-dom';
import proj4 from 'proj4';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
// eslint-disable-next-line import/no-unresolved
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import LayerPanel from './LayerPanel';
import './Map.css';
import Legend from './Legend';
import LayerOpacitySlider from './LayerOpacitySlider';
import MapProjectionButton from './MapProjectionButton';
import BasemapSwitcher from './BasemapSwitcher';
import ScreenshotButton from './ScreenshotButton';
import { getDatasetById } from '../../api/datasets';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOXGLJS_ACCESS_TOKEN;

function Map() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  // map
  const mapContainer = useRef(null);
  const map = useRef(null);

  // globe
  const [lng] = useState(-22.395619);
  const [lat] = useState(14.264383);
  const [zoom] = useState(3);

  // target dataset
  const [dataset, setDataset] = useState();

  // selected layer (e.g. a time series layer is a child of a theme)
  const [selectedLayer, setSelectedLayer] = useState([]);
  const [layerType, setLayerType] = useState('fill');

  // layer opacity slider
  const [opacitySliderValue, setOpacitySliderValue] = useState(80);

  // basemap style
  const [targetStyle, setTargetStyle] = useState('mapbox://styles/spatialdev/ckxi7of9539yo16pa3p0mcu18'); // dark

  // legend
  const [legendTitle, setLegendTitle] = useState('');
  const [legendItems, setLegendItems] = useState([]);
  const [legendUnits, setLegendUnits] = useState('');

  /**
   * Utility for loading sources and layers
   */
  const loadMapLayers = async (targetId, zoomToTarget) => {
    if (map.current.isStyleLoaded()) {
      //
      // console.log('loadMapLayers...');
      // console.log(map.current.getStyle().layers);

      // using the id from the query string and fetch the dataset from OpenSearch
      const response = await getDatasetById(targetId);

      // get the dataset
      const dataset = response[0];
      // //
      // console.log(dataset);

      if (dataset && dataset.layer) {
        // set the selected layer
        setDataset(dataset);

        // look up layer underneath based on style
        let layerUnderneath;
        if (targetStyle === 'mapbox://styles/mapbox/satellite-streets-v11') {
          // layerUnderneath = 'tunnel-primary-secondary-tertiary-case';
          layerUnderneath = 'water-line-label';
        } else {
          // layerUnderneath = 'building';
          layerUnderneath = 'waterway-label';
        }

        // use the dataset properties to add as a data source and layer
        if (dataset.source.type === 'vector') {
        // add source
          if (!map.current.getSource(dataset.source.id)) {
            map.current.addSource(dataset.source.id, {
              type: dataset.source.type,
              tiles: dataset.source.tiles,
            });
          }
          // add layer
          if (!map.current.getLayer(dataset.layer.id)) {
            map.current.addLayer(dataset.layer, layerUnderneath);
          }

          // zoom to bounds
          if (zoomToTarget) {
            map.current.fitBounds([dataset.extent[0], dataset.extent[1]]);
          }

          // TODO: Popup for Demo - REFACTOR
          map.current.on('click', dataset.layer.id, (e) => {
            //
            console.log(dataset.layer.id);
            console.log(e.features[0].properties);
            const popup = new mapboxgl.Popup({ offset: 25 });
            popup.setLngLat(e.lngLat);
            // TODO: Popup Attributes for Demo - REFACTOR
            if (dataset.layer.id === 'padus_federal_lands_layer') {
              popup.setHTML(
                `<b>Protected Areas</b>
                <div>Name: ${e.features[0].properties.Unit_Nm}</div>
                <div>Owner: ${e.features[0].properties.d_Mang_Nam}</div>
                <div>Type: ${e.features[0].properties.d_Mang_Typ}</div>
                <div>Source Date: ${e.features[0].properties.Src_Date}</div>
                `,
              );
            }
            if (dataset.layer.id === 'wildfire_crisis_strategy_landscapes_layer') {
              popup.setHTML(
                `<b>Wildfire Crisis Area</b>
                <div>Name: ${e.features[0].properties.NAME}</div>
                <div>Investment Start Year: ${e.features[0].properties.INVESTMENTSTARTYEAR}</div>
                <div>Landscape Acres: ${e.features[0].properties.LANDSCAPEACRES}</div>
                <div>Project Id: ${e.features[0].properties.PROJECTID}</div>
                <div>Region: ${e.features[0].properties.REGION}</div>
                <div>State: ${e.features[0].properties.STATE}</div>
                `,
              );
            }
            if (dataset.layer.id === 'nature_dependent_people_by_country_layer') {
              popup.setHTML(
                `<b>Nature Dependent People</b>
                <div>Country: ${e.features[0].properties.country}</div>
                <div>Level Of Dependency: ${e.features[0].properties['ndp\n(3+4)'].toFixed(3)}</div>
                `,
              );
            }
            if (dataset.layer.id === 'national_risk_index_layer') {
              popup.setHTML(
                `<b>National Risk Index</b>
                <div>County: ${e.features[0].properties.COUNTY}</div>
                <div>Population: ${e.features[0].properties.POPULATION}</div>
                <div>Risk Score: ${e.features[0].properties.RISK_SCORE.toFixed(1)}</div>
                <div>Risk Rating: ${e.features[0].properties.RISK_RATNG}</div>
                <div>State: ${e.features[0].properties.STATE}</div>
                `,
              );
            }
            if (dataset.layer.id === 'northwest_predicted_stream_temperature_layer') {
              popup.setHTML(
                `<b>NW Predicted Stream Temp</b>
                <div>Name: ${e.features[0].properties.GNIS_NAME}</div>
                <div>Baseline: ${e.features[0].properties.S1_93_11} Degrees Celsius</div>
                <div>Future 2040s: ${e.features[0].properties.S30_2040D} Degrees Celsius</div>
                <div>Future 2080s: ${e.features[0].properties.S32_2080D} Degrees Celsius</div>
                `,
              );
            }
            if (dataset.layer.id === 'red_list_index_2022_layer') {
              popup.setHTML(
                `<b>Red List Index</b>
                <div>Country: ${e.features[0].properties.country_rli}</div>
                <div>Index: ${e.features[0].properties['2022']}</div>
                `,
              );
            }
            if (dataset.layer.id === 'national_wild_scenic_rivers_layer') {
              popup.setHTML(
                `<b>National Wild & Scenic Rivers</b>
                <div>Name: ${e.features[0].properties.GNIS_NAME}</div>
                <div>Classification: ${e.features[0].properties.CLASSIFICATION}</div>
                <div>Segment Miles: ${e.features[0].properties.SEGMENT_MILES}</div>
                <div>List: ${e.features[0].properties.ORV_LIST}</div>
                <div>Agency: ${e.features[0].properties.AGENCY}</div>
                <div>County: ${e.features[0].properties.COUNTY}</div>
                `,
              );
            }
            if (dataset.layer.id === 'firms_active_fire_3857_layer') {
              popup.setHTML(
                `<b>FIRMS Active Fire</b>
                <div>ACQ DATE: ${e.features[0].properties.ACQ_DATE}</div>
                <div>ACQ TIME: ${e.features[0].properties.ACQ_TIME}</div>
                <div>BRIGHT TI4: ${e.features[0].properties.BRIGHT_TI4}</div>
                <div>BRIGHT TI5: ${e.features[0].properties.BRIGHT_TI5}</div>
                <div>CONFIDENCE: ${e.features[0].properties.CONFIDENCE}</div>
                <div>LATITUDE: ${e.features[0].properties.LATITUDE}</div>
                <div>LONGITUDE: ${e.features[0].properties.LONGITUDE}</div>
                `,
              );
            }
            if (dataset.layer.id === 'usdm_20230214_layer') {
              popup.setHTML(
                `<b>Drought Level</b>
                <div>DM: ${e.features[0].properties.DM}</div>
                `,
              );
            }
            if (dataset.layer.id === 'fifty_reefs_layer') {
              popup.setHTML(
                `<b>50 Reefs Analysis</b>
                <div>Id: ${e.features[0].properties.ID}</div>
                <div>Score: ${e.features[0].properties.score.toFixed(4)}</div>
                `,
              );
            }
            if (dataset.layer.id === 'lametro_meni_layer') {
              popup.setHTML(
                `<b>MENI</b>
                <div>Tier: ${e.features[0].properties.MENI_Tier}</div>
                <div>City: ${e.features[0].properties.City}</div>
                <div>Area (Sq mi): ${e.features[0].properties.Area_SqMi.toFixed(2)}</div>
                <div>Pop BIPOC: ${e.features[0].properties.POP_BIPOC}</div>
                <div>Pctl BIPOC: ${e.features[0].properties.Pctl_BIPOC}</div>
                <div>Pctl 160K BIPOC: ${e.features[0].properties.Pctl_I60K_BIPOC}</div>
                <div>Pctl 160K NoVeh: ${e.features[0].properties.Pctl_I60K_NoVeh}</div>
                <div>Pctl Inc60K: ${e.features[0].properties.Pctl_Inc60K}</div>
                <div>Pctl MENI: ${e.features[0].properties.Pctl_MENI}</div>
                <div>Pctl NoVeh: ${e.features[0].properties.Pctl_NoVeh}</div>
                <div>Rate BIPOC: ${e.features[0].properties.Rate_BIPOC}</div>
                <div>Rate Inc60: ${e.features[0].properties.Rate_Inc60}</div>
                <div>Rate NoVeh: ${e.features[0].properties.Rate_NoVeh}</div>
                <div>Tot HH: ${e.features[0].properties.Tot_HH}</div>
                <div>Tot Pop: ${e.features[0].properties.Tot_Pop}</div>
                `,
              );
            }
            if (dataset.layer.id === 'lametro_calenviroscreen4_layer') {
              popup.setHTML(
                `<div style="overflow-x: scroll; max-height: 400px;">
                <b>CalEnviroScreen 4.0</b>
                <div>CIscoreP: ${e.features[0].properties.CIscoreP}</div>
                <div>CIscore: ${e.features[0].properties.CIscore}</div>
                <div>Tract: ${e.features[0].properties.Tract}</div>
                <div>ZIP: ${e.features[0].properties.ZIP}</div>
                <div>County: ${e.features[0].properties.County}</div>
                <div>City: ${e.features[0].properties.City_1}</div>
                <div>Population: ${e.features[0].properties.Population}</div>
                <div>Ozone: ${e.features[0].properties.Ozone}</div>
                <div>Ozone_Pctl: ${e.features[0].properties.Ozone_Pctl}</div>
                <div>PM2_5: ${e.features[0].properties.PM2_5}</div>
                <div>PM2_5_Pctl: ${e.features[0].properties.PM2_5_Pctl}</div>
                <div>Diesel_PM: ${e.features[0].properties.Diesel_PM}</div>
                <div>Pesticides: ${e.features[0].properties.Pesticides}</div>
                <div>Pesticid_1: ${e.features[0].properties.Pesticid_1}</div>
                <div>Tox_Rele_1: ${e.features[0].properties.Tox_Rele_1}</div>
                <div>Traffic_Pc: ${e.features[0].properties.Traffic_Pc}</div>
                <div>Drinking_W: ${e.features[0].properties.Drinking_W}</div>
                <div>DrinkingWa: ${e.features[0].properties.DrinkingWa}</div>
                <div>Lead_Pctl: ${e.features[0].properties.Lead_Pctl}</div>
                <div>Cleanups: ${e.features[0].properties.Cleanups}</div>
                <div>Cleanups_P: ${e.features[0].properties.Cleanups_P}</div>
                <div>Groundwate: ${e.features[0].properties.Groundwate}</div>
                <div>GW_Threats: ${e.features[0].properties.GW_Threats}</div>
                <div>Haz_Waste: ${e.features[0].properties.Haz_Waste}</div>
                <div>Haz_Waste_: ${e.features[0].properties.Haz_Waste_}</div>
                <div>Imp_Water_: ${e.features[0].properties.Imp_Water_}</div>
                <div>ImpWaterBo: ${e.features[0].properties.ImpWaterBo}</div>
                <div>Solid_Wast: ${e.features[0].properties.Solid_Wast}</div>
                <div>Pollution: ${e.features[0].properties.Pollution}</div>
                <div>PollutionS: ${e.features[0].properties.PollutionS}</div>
                <div>Pollution_: ${e.features[0].properties.Pollution_}</div>
                <div>Asthma: ${e.features[0].properties.Asthma}</div>
                <div>Asthma_Pct: ${e.features[0].properties.Asthma_Pct}</div>
                <div>Low_Birth_: ${e.features[0].properties.Low_Birth_}</div>
                <div>LowBirthW_: ${e.features[0].properties.LowBirthW_}</div>
                <div>Cardiovasc: ${e.features[0].properties.Cardiovasc}</div>
                <div>Cardiova_1: ${e.features[0].properties.Cardiova_1}</div>
                <div>Education: ${e.features[0].properties.Education}</div>
                <div>Education_: ${e.features[0].properties.Education_}</div>
                <div>Ling_Isol: ${e.features[0].properties.Ling_Isol}</div>
                <div>Ling_Isol_: ${e.features[0].properties.Ling_Isol_}</div>
                <div>Poverty: ${e.features[0].properties.Poverty}</div>
                <div>Poverty_Pc: ${e.features[0].properties.Poverty_Pc}</div>
                <div>Unemployme: ${e.features[0].properties.Unemployme}</div>
                <div>Unemploy_P: ${e.features[0].properties.Unemploy_P}</div>
                <div>HousBurd: ${e.features[0].properties.HousBurd}</div>
                <div>HousBurd_P: ${e.features[0].properties.HousBurd_P}</div>
                <div>PopChar: ${e.features[0].properties.PopChar}</div>
                <div>PopCharSco: ${e.features[0].properties.PopCharSco}</div>
                <div>PopCharP: ${e.features[0].properties.PopCharP}</div>
                <div>Child_10: ${e.features[0].properties.Child_10}</div>
                <div>Pop_10_64: ${e.features[0].properties.Pop_10_64}</div>
                <div>Elderly_65: ${e.features[0].properties.Elderly_65}</div>
                <div>Hispanic: ${e.features[0].properties.Hispanic}</div>
                <div>White: ${e.features[0].properties.White}</div>
                <div>African_Am: ${e.features[0].properties.African_Am}</div>
                <div>Native_Ame: ${e.features[0].properties.Native_Ame}</div>
                <div>Asian_Amer: ${e.features[0].properties.Asian_Amer}</div>
                <div>Pacific_Is: ${e.features[0].properties.Pacific_Is}</div>
                <div>Other_Mult: ${e.features[0].properties.Other_Mult}</div>
                </div>
                `,
              );
            }
            if (dataset.layer.id === 'lametro_railways_layer') {
              popup.setHTML(
                `<b>LA Metro Rail Lines</b>
                <div style="word-wrap: break-word">
                <div>Route Type: ${e.features[0].properties.route_type}</div>
                <div>Filename: ${e.features[0].properties.filename}</div>
                </div>
                `,
              );
            }
            if (dataset.layer.id === 'lametro_rail_stations_layer') {
              popup.setHTML(
                `<b>LA Metro Rail Stations</b>
                <div style="word-wrap: break-word">
                <div>Name: ${e.features[0].properties.STOP_NAME}</div>
                <div>Id: ${e.features[0].properties.STOP_ID}</div>
                <div>Latitude: ${e.features[0].properties.STOP_LAT}</div>
                <div>Longitude: ${e.features[0].properties.STOP_LON}</div>
                </div>
                `,
              );
            }
            if (dataset.layer.id === 'lametro_efc_layer') {
              popup.setHTML(
                `<b>LA Metro EFC 2022</b>
                <div style="word-wrap: break-word">
                <div>City: ${e.features[0].properties.City}</div>
                <div>Jurisdiction Type: ${e.features[0].properties.JurisdictionType}</div>
                <div>MENI Tier: ${e.features[0].properties.MENI_Tier}</div>
                <div>Pop BIPOC: ${e.features[0].properties.POP_BIPOC}</div>
                <div>Pctl BIPOC: ${e.features[0].properties.Pctl_BIPOC}</div>
                <div>Pctl 160K BIPOC: ${e.features[0].properties.Pctl_I60K_BIPOC}</div>
                <div>Pctl 160K NoVeh: ${e.features[0].properties.Pctl_I60K_NoVeh}</div>
                <div>Pctl Inc60K: ${e.features[0].properties.Pctl_Inc60K}</div>
                <div>Pctl MENI: ${e.features[0].properties.Pctl_MENI}</div>
                <div>Pctl NoVeh: ${e.features[0].properties.Pctl_NoVeh}</div>
                <div>Rate BIPOC: ${e.features[0].properties.Rate_BIPOC}</div>
                <div>Rate Inc60: ${e.features[0].properties.Rate_Inc60}</div>
                <div>Rate NoVeh: ${e.features[0].properties.Rate_NoVeh}</div>
                <div>Tot HH: ${e.features[0].properties.Tot_HH}</div>
                <div>Tot Pop: ${e.features[0].properties.Tot_Pop}</div>
                </div>
                `,
              );
            }
            if (dataset.layer.id === 'NumberDaysAbove95_RCP85_2044_UHI_EFC_I_20230608_layer') {
              popup.setHTML(
                `<b>Attributes</b>
                <div style="word-wrap: break-word">
                <div>Days Range: ${e.features[0].properties.DaysRange}</div>
                <div>Line Name: ${e.features[0].properties.Line_Name}</div>
                <div>UHII Range: ${e.features[0].properties.UHII_Range}</div>
                </div>
                `,
              );
            }
            if (dataset.layer.id === 'NumberDaysAbove95_RCP85_2060_UHI_EFC_I_20230608_layer') {
              popup.setHTML(
                `<b>Attributes</b>
                <div style="word-wrap: break-word">
                <div>Days Range: ${e.features[0].properties.DaysRange}</div>
                <div>Line Name: ${e.features[0].properties.Line_Name}</div>
                <div>UHII Range: ${e.features[0].properties.UHII_Range}</div>
                </div>
                `,
              );
            }
            if (dataset.layer.id === 'NumberDaysAbove95_RCP85_Base_2005_UHI_EFC_I_20230608_layer') {
              popup.setHTML(
                `<b>Attributes</b>
                <div style="word-wrap: break-word">
                <div>Days Range: ${e.features[0].properties.DaysRange}</div>
                <div>Line Name: ${e.features[0].properties.Line_Name}</div>
                <div>UHII Range: ${e.features[0].properties.UHII_Range}</div>
                </div>
                `,
              );
            }
            if (dataset.layer.id === 'RailLines_PrecipDays1in_Base_I_20230612_layer') {
              popup.setHTML(
                `<b>Attributes</b>
                <div style="word-wrap: break-word">
                <div>Days Range: ${e.features[0].properties.DaysRange1}</div>
                <div>Precip Days: ${e.features[0].properties.PrecipDays}</div>
                <div>Line Name: ${e.features[0].properties.Line_Name}</div>
                <div>Length (mi): ${e.features[0].properties.LengthMi.toFixed(1)}</div>
                </div>
                `,
              );
            }
            if (dataset.layer.id === 'RailLines_PrecipDays1in_2030_I_20230612_layer') {
              popup.setHTML(
                `<b>Attributes</b>
                <div style="word-wrap: break-word">
                <div>Days Range: ${e.features[0].properties.DaysRange1}</div>
                <div>Precip Days: ${e.features[0].properties.PrecipDays}</div>
                <div>Line Name: ${e.features[0].properties.Line_Name}</div>
                <div>Length (mi): ${e.features[0].properties.LengthMi.toFixed(1)}</div>
                </div>
                `,
              );
            }
            if (dataset.layer.id === 'RailLines_PrecipDays1in_2050_I_20230612_layer') {
              popup.setHTML(
                `<b>Attributes</b>
                <div style="word-wrap: break-word">
                <div>Days Range: ${e.features[0].properties.DaysRange1}</div>
                <div>Precip Days: ${e.features[0].properties.PrecipDays}</div>
                <div>Line Name: ${e.features[0].properties.Line_Name}</div>
                <div>Length (mi): ${e.features[0].properties.LengthMi.toFixed(1)}</div>
                </div>
                `,
              );
            }
            if (dataset.layer.id === 'usa-flood-hazard-areas-la_layer') {
              popup.setHTML(
                `<b>Flood Hazard Area</b>
                <div style="word-wrap: break-word">
                <div>Name: ${e.features[0].properties.ClassName}</div>
                <div>Grid Code: ${e.features[0].properties.gridcode}</div>
                </div>
                `,
              );
            }
            popup.addTo(map.current);
          });
          // change the cursor to a pointer when the mouse is over the places layer.
          map.current.on('mouseenter', dataset.layer.id, () => {
            map.current.getCanvas().style.cursor = 'pointer';
          });
          // change it back to a pointer when it leaves.
          map.current.on('mouseleave', dataset.layer.id, () => {
            map.current.getCanvas().style.cursor = '';
          });
        } else if (dataset.source.type === 'raster') {
          //
          console.log(dataset.source.tiles);
          // add source
          if (!map.current.getSource(dataset.source.id)) {
            map.current.addSource(dataset.source.id, {
              type: dataset.source.type,
              tiles: dataset.source.tiles,
              tileSize: dataset.source.tileSize,
            });
          }
          // add layer
          if (!map.current.getLayer(dataset.layer.id)) {
            map.current.addLayer(dataset.layer, layerUnderneath);
          }
          // zoom to bounds
          map.current.fitBounds([dataset.extent[0], dataset.extent[1]]);
        }
      }
    }
  };

  /**
   * Initial load
   */
  useEffect(() => {
    if (map.current) return; // initiatlize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: targetStyle,
      center: [lng, lat],
      zoom,
      preserveDrawingBuffer: true, // need this for screenshot!
    });

    // add navigation control
    const navControl = new mapboxgl.NavigationControl();
    map.current.addControl(navControl, 'top-right');

    // add scale bar
    const scale = new mapboxgl.ScaleControl({
      maxWidth: 80,
      unit: 'imperial',
    });
    map.current.addControl(scale);

    // add full screen
    map.current.addControl(new mapboxgl.FullscreenControl(), 'top-right');

    // Use shorthand with default parameters
    map.current.setProjection('mercator');

    // Load sources and layers on top of map style -- see useEffect [dataset]
    map.current.on('load', () => {
      loadMapLayers(id, true); // pass id from query string on initial load
    });

    // Add draw
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
    });
    map.current.addControl(draw, 'top-right');

    // Utility for handling drawing events
    function handleDraw() {
      const data = draw.getAll();

      if (data.features.length > 0) {
        // get latitude longitude coords
        const coords = data.features[0].geometry.coordinates[0];
        coords.forEach((coord) => {
          //
          console.log(coord);
        });

        // convert from lat-lng coordinates to mercator coordinates
        const mercatorCoords = [];
        coords.forEach((coord) => {
          const projCoord = proj4(proj4.defs('EPSG:3857')).forward(coord);
          mercatorCoords.push(projCoord);
        });
        //
        console.log('projected coordinates');
        mercatorCoords.forEach((coord) => {
          //
          console.log(coord[0], coord[1]);
        });

        // make request for summary analysis inside geometry
        // TODO

        // show summary analysis in dashboard
        // TODO
      } else {
        // clear summary analysis in dashboard`
        // TODO
      }
    }
    map.current.on('draw.create', handleDraw);
    map.current.on('draw.delete', handleDraw);
    map.current.on('draw.update', handleDraw);
  }, []);

  useEffect(() => {
    //
    // console.log('Map useEffect [dataset]');

    // legend configuration
    if (dataset && dataset.layer && dataset.legend) {
      //
      // console.log('dataset', dataset);
      // console.log('dataset.layer', dataset.layer);
      // console.log('dataset.legend.title', dataset.legend.title);
      // console.log('dataset.legend.items', dataset.legend.items);
      // console.log('dataset.legend.units', dataset.legend.units);
      setSelectedLayer(dataset.layer);
      setLayerType(dataset.layer.type);
      setLegendTitle(dataset.legend.title);
      setLegendItems(dataset.legend.items);
      setLegendUnits(dataset.legend.units);
    }
  }, [dataset]);

  /**
   * useEffect when selectedLayer changes (e.g. user changes the time slider)
   */
  useEffect(() => {
    // mouse cursor is a pointer when moving over the selected layer
    map.current.on('mousemove', selectedLayer.sourceLayer, () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });

    // mouse cursor is default when mouse out of selected layer
    map.current.on('mouseout', selectedLayer.sourceLayer, () => {
      map.current.getCanvas().style.cursor = 'default';
    });

    // remove previous popup
    const thePop = document.getElementsByClassName('mapboxgl-popup');
    if (thePop.length) {
      thePop[0].remove();
    }
  }, [selectedLayer]);

  /**
   * Callback used by OpacitySlider child component to provide this component the slider value after a user changes it.
   * @param {*} opacityValue
   */
  const callbackOpacitySliderChange = (opacityValue) => {
    // update local state for opacity value
    setOpacitySliderValue(opacityValue);
  };

  /**
   * useEffect when opacitySliderValue is changed (e.g. user moved the layer opacity slider)
   */
  useEffect(() => {
    // update layer opacity for map layer
    if (map.current.isStyleLoaded()) {
      map.current.setPaintProperty(selectedLayer.id, `${selectedLayer.type}-opacity`, parseInt(opacitySliderValue, 10) / 100);
    }
  }, [opacitySliderValue]);

  /**
   * Callback used by BasemapSwitcher child component to provide this component with the selected basemap
   * @param {*} basemap
   */
  const callbackBasemapChange = (basemap) => {
    setTargetStyle(basemap);
  };

  /**
   * useEffect after the basemap is changed
   */
  useEffect(() => {
    // change the base map style
    map.current.setStyle(targetStyle);

    // add sources and layers on top of map style
    loadMapLayers(id, false);
  }, [targetStyle]);

  /**
   * Updates the legendItems with a new colormap
   * @param {*} legendItems - Current legend items (i.e. array of { color: '#ff0000', text: 'DM1' })
   * @param {*} colorMap - ColorMap (i.e. array of hex colors)
   * @returns
   */
  const updateLegendItemsColors = (legendItems, colorMap) => {
    //
    console.log('legendItems', legendItems);
    console.log('colorMap', colorMap);

    if (legendItems.length !== colorMap.length) {
      throw new Error('Length of legend and colors array do not match');
    }
    return legendItems.map((item, index) => ({
      ...item,
      color: colorMap[index],
    }));
  };

  /**
   * Update a layer's fill-color expression with new colors
   * @param {*} paintProperty The current layer's fill-color expression
   * @param {*} colorMap The new colors to use to update the fill-color expression
   * @returns A new fill-color expression with udpated colors
   */
  const updateFillColorExpression = (fillColorExpression, colorMap) => {
    if (fillColorExpression[0] === 'match') { // match is used for a categorical fill color expression
      // start creating new paint property
      const updatedfillColorExpression = ['match', fillColorExpression[1]];

      let colorIndex = 0;
      // add paint property values and colors
      for (let i = 2; i < fillColorExpression.length; i += 2) {
        if (colorMap[colorIndex] !== undefined) {
          updatedfillColorExpression.push(fillColorExpression[i], colorMap[colorIndex]);
        }
        colorIndex += 1;
      }
      // add default color
      updatedfillColorExpression.push('gray');
      return updatedfillColorExpression;
    }
    // here we assume a simple fill-color expression with just a color
    return colorMap[0];
  };

  /**
   * A utility to make a light color from the input
   * @param {*} hexColor The hexcolor that we'll use to get a lighter color from
   * @param {*} amount The amount ligher
   * @returns a new hex color
   */
  const lightenHexColor = (hexColor, amount = 20) => {
    // convert hex color to RGB values
    const [r, g, b] = hexColor.match(/\w\w/g).map((hex) => parseInt(hex, 16));

    // increase RGB values by amount
    const newR = Math.min(255, Math.round(r * (1 + amount / 100)));
    const newG = Math.min(255, Math.round(g * (1 + amount / 100)));
    const newB = Math.min(255, Math.round(b * (1 + amount / 100)));

    // convert updated RGB values back to hex color
    const newHexColor = `#${[newR, newG, newB].map((c) => c.toString(16).padStart(2, '0')).join('')}`;

    return newHexColor;
  };

  /**
   * Callback used by Legend child component to provide this component with the selected colormap
   * @param {*} colorMap
   */
  const callbackColorMapChange = (colorMap) => {
    // update the legend colors
    const updatedLegend = updateLegendItemsColors(legendItems, colorMap.colors);
    setLegendItems(updatedLegend);

    // based on data source type (raster | vector) update the layer symbology
    if (dataset.source.type === 'vector') {
      // update vector layer symbology
      const layerType = map.current.getLayer(selectedLayer.id).type;
      if (layerType === 'fill') {
        // get layer's fill-color expression
        const currentFillColorExpression = map.current.getPaintProperty(selectedLayer.id, `${layerType}-color`);

        // update colors in fill-color expression
        const updatedFillColorExpression = updateFillColorExpression(currentFillColorExpression, colorMap.colors);

        // update layer with new fill-color expression
        map.current.setPaintProperty(selectedLayer.id, `${layerType}-color`, updatedFillColorExpression);

        // for simple fill-color (i.e. just one color) create an outline that is lighter than the input
        if (colorMap.colors.length === 1) {
          map.current.setPaintProperty(selectedLayer.id, `${layerType}-outline-color`, lightenHexColor(colorMap.colors[0]));
        }
      } else {
        // change cilor of circle-color, line-color, etc.
        // TODO: Handle other types (symbl, heatmap, raster, fill-extrusion, video)
        map.current.setPaintProperty(selectedLayer.id, `${layerType}-color`, colorMap.colors[0]);
      }
    } else {
      // update raster layer symbology, start with updating the tile url (where colormap is defined)
      const currentDataSourceURL = dataset.source.tiles[0];
      const urlObj = new URL(currentDataSourceURL);
      const qsParams = new URLSearchParams(urlObj.search);
      qsParams.set('colormap_name', colorMap.name);
      const updatedDataSourceURL = `${process.env.REACT_APP_API_TITILER}/{z}/{x}/{y}?${qsParams.toString()}`;

      // get the layer's raster-opacity - use when re-adding layer
      const rasterOpacity = map.current.getPaintProperty(dataset.layer.id, 'raster-opacity');

      // remove existing layer
      map.current.removeLayer(dataset.layer.id);

      // remove existing data source
      map.current.removeSource(dataset.source.id);

      // add updated data source
      map.current.addSource(dataset.source.id, {
        type: 'raster',
        tiles: [updatedDataSourceURL],
        tileSize: 256,
        minZoom: 0,
        maxzoom: 22,
      });

      // add update layer
      map.current.addLayer({
        id: dataset.layer.id,
        type: 'raster',
        source: dataset.source.id,
        minZoom: 0,
        maxzoom: 22,
        paint: {
          'raster-opacity': rasterOpacity,
        },
      });
    }
  };

  /**
   * Callback sent to LayerPanel > CardTemplate component - handles when the user clicks on a card in the Add Data Dialog
   */
  const callbackCardClicked = (item) => {
    console.log('handle card clicked');
    console.log(item);
    loadMapLayers(item.id, true);
  };

  return (
    <div>
      <LayerPanel callback={callbackCardClicked}>
        {(dataset) ? (
          <Legend
            title={legendTitle}
            items={legendItems}
            units={legendUnits}
            layerType={layerType}
            callback={callbackColorMapChange}
          />
        ) : (null)}
        <Divider />
        <LayerOpacitySlider callback={callbackOpacitySliderChange} />
        <Divider />
      </LayerPanel>
      <BasemapSwitcher callback={callbackBasemapChange} />
      <div ref={mapContainer} className="map" />
      <MapProjectionButton map={map.current} />
      <ScreenshotButton map={map.current} />
    </div>
  );
}

export default Map;
