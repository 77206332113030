import React from 'react';

function Account() {
  return (
    <div style={{ padding: 16, marginTop: 78, marginBottom: 60 }}>
      My Account
    </div>
  );
}

export default Account;
