import React from 'react';
// import { useHistory } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

export default function CardTemplate({ data, callback }) {
  if (!data) {
    return <div>Loading...</div>;
  }

  // const history = useHistory();

  // const handleOnClick = () => {
  //   history.push(`/catalogdetails?id=${data.id}`);
  // };

  const handleOnClick = () => {
    callback(data);
  };

  return (
    <Card sx={{ maxWidth: 468, height: 410, cursor: 'pointer' }} onClick={handleOnClick}>
      <CardHeader
        sx={{ height: 60 }}
        title={<div style={{ fontSize: 14, fontWeight: 700 }}>{data.title}</div>}
        subheader=""
      />
      <CardMedia
        component="img"
        height="170"
        image={`${data.linkToMapScreenshot}?access_token=${process.env.REACT_APP_MAPBOXGLJS_ACCESS_TOKEN}`}
        alt="map"
      />
      <CardContent>
        <div style={{ fontSize: 14, maxHeight: 80 }}>
          {data.description.length > 116 ? `${data.description.slice(0, 116)}...` : data.description}
        </div>
        {(data.tags && data.tags.length > 0) && (
          <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems="flex-start" style={{ marginTop: 20 }}>
            {data.tags.split(',').map((tag) => (
              <Chip key={tag} label={tag} size="small" />
            ))}
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}
