import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import {
  PieChart, Pie, Cell,
} from 'recharts';
import { v4 as uuidv4 } from 'uuid';
import { Colors } from '../AppTheme';

export default function HomeCard({ data }) {
  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <Card style={{ backgroundColor: Colors.dashboardLightGray, padding: '16px 16px 0 16px' }}>
      <CardHeader
        action={(
          <IconButton>
            <CloseIcon style={{ color: 'black' }} />
          </IconButton>
        )}
        title={(
          <div style={{
            backgroundColor: Colors.yellow,
            padding: 10,
            display: 'inline-block',
            whiteSpace: 'nowrap',
          }}
          >
            <div style={{ fontSize: '40px', fontWeight: 400 }}>{data.topic}</div>
          </div>
)}
      />
      <CardContent>
        {data.goals.map((goal) => (
          <div key={uuidv4()}>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ backgroundColor: Colors.dashboardDarkGray, padding: 24 }}
            >
              <Stack direction="column" spacing={1} style={{ minWidth: 240 }}>
                {goal.targets.map((target) => (
                  <div key={uuidv4()}>
                    <Stack direction="column" spacing={1} style={{ color: Colors.dashboardGreen }}>
                      <div style={{ fontSize: '48px', lineHeight: '56px', fontWeight: 700 }}>{target.value}</div>
                      <div style={{
                        fontSize: '24px', lineHeight: '28px', fontWeight: 700, maxWidth: 180,
                      }}
                      >
                        {target.subject}
                      </div>
                    </Stack>
                  </div>
                ))}
              </Stack>
              <Stack direction="column" spacing={4}>
                <div>
                  <div style={{ color: Colors.dashboardGreen, fontSize: '20px' }}>THE TARGET</div>
                  <div style={{ marginTop: 6, fontSize: '20px' }}>{goal.targetDescription}</div>
                </div>
                <div>
                  <div style={{ color: Colors.dashboardGreen, fontSize: '20px' }}>THE HOW</div>
                  <div style={{ marginTop: 6, fontSize: '20px' }}>{goal.targetStrategy}</div>
                </div>
              </Stack>
              <Stack direction="column" spaceing={1} style={{ minWidth: 250, paddingLeft: 50 }}>
                <div style={{ fontSize: '24px', fontWeight: 400, letterSpacing: '0.08em' }}>
                  PROGRESS:
                  &nbsp;
                  {goal.progress[0].value}
                  %
                </div>
                <PieChart width={200} height={200}>
                  <Pie
                    stroke="none"
                    data={goal.progress}
                    cx={100}
                    cy={100}
                    innerRadius={30}
                    outerRadius={80}
                    fill="#D9D9D9"
                    paddingAngle={1}
                    dataKey="value"
                    isAnimationActive={false}
                  >
                    <Cell fill="#44933F" />
                  </Pie>
                </PieChart>
              </Stack>
            </Stack>
            <br />
          </div>
        ))}
      </CardContent>
    </Card>
  );
}
