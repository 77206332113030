// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';

export const getDatasetById = async (id) => {
  const results = [];
  const url = `${process.env.REACT_APP_OPENSEARCH_API_BASEURL}/api/search?index=${process.env.REACT_APP_SEARCH_INDEX}&query={"query":{"match":{"_id":"${id}"}}}`;
  try {
    const response = await axios.get(url);

    if (response.data.hits.hits.length > 0) {
      response.data.hits.hits.forEach((hit) => {
        // eslint-disable-next-line no-underscore-dangle
        results.push(hit._source);
      });
    }

    return results;
  } catch (error) {
    return error.toJSON();
  }
};

export default getDatasetById;
