import { createTheme } from '@mui/material';

export const Colors = {
  black: '#2D3142',
  lightGray: '#CBCBCB',
  white: '#FFFFFF',
  offwhite: '#F6F6F2',
  darkBlue: '#02285A',
  lightBlue: '#007BAD',
  green: '#10BD9F',
  primaryDark: '#29323c',
  secondaryDark: '#242730',
  aqua: '#1fbad6',

  // navivation
  navGray: '#333333',

  // kepler
  darkBackground: '#26282F',
  lighterBackground: '#2B323B',
  blue: '#58B7D3',
  gray: '#555C6B',

  // sections colors
  freshwater: '#99DAE0',
  ocean: '#00A0AF',
  climate: '#FCDE4C',
  biodiversity: '#9FC04D',
  lands: '#E97010',
  people: '#003B40',
  yellow: '#FFE04F',
  yellowActive: '#dac046',

  // dashboard
  dashboardLightGray: '#F1F1F1',
  dashboardDarkGray: '#E5E5E5',
  dashboardGreen: '#44933F',
};

export const AppTheme = createTheme({
  palette: {
    primary: {
      main: Colors.darkBlue,
    },
    secondary: {
      main: Colors.blue,
    },
    text: {
      primary: Colors.black,
      secondary: Colors.darkBlue,
    },
  },
  typography: {
    h1: {
      fontSize: 48,
    },
    h2: {
      fontSize: 24,
    },
    h3: {
      fontSize: 22,
    },
    h4: {
      fontSize: 16,
    },
    h5: {
      fontSize: '14px',
    },
    h6: {
      fontSize: '0.75rem',
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 12,
    },
  },
  background: {
    default: '#f5f6f7',
  },
});
